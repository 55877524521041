import React from 'react';
import NavigationBar from './components/NavigationBar';

// Pages
// import Home from './components/Home';
// import AboootPage from './components/Abooot';
// import BlogPage from './components/Blog';
// import MyProject from './components/MyProject';

//  CSS
import './App.css'

function App() {
  return (
    <header>
      <NavigationBar /> 
    </header>
  );
}

export default App;
