import React from "react"
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom"

import Home from "../pages/Home"
import AboootPage from "../pages/Abooot"
import BlogPage from "../pages/Blog"

type Props = {
    navMain: string
}

const NavList = ({ navMain }: Props) => {

    return (
        <Router>
            <div className={navMain}>
                <Link to="/">Home</Link>
                <Link to="/abooot">Abooot</Link>
                <Link to="/blog">Blog</Link>
            </div>
            <div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/abooot" element={<AboootPage textAbooot={"About Page"} />} />
                    <Route path="/blog" element={<BlogPage textBlog={"Blog page"} />} />
                </Routes>
            </div>
        </Router>
    )
}

export default NavList