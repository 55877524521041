import React from "react"
import { useState } from "react"

import {
  PaperAirplaneIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline"
import NavList from "./NavList"

function NavigationBar() {
    const [toggleMenu, setToggleMenu] = useState(false)

    return (
        <div>
            <nav>
                <div className="max-w-7xl mx-auto">
                    <div className="flex mx-auto justify-between w-5/6">
                        {/** Primary meny and logo */}
                        <div className="flex items-center gap-16 my-12">
                            {/** Logo */}
                            <div>
                                <a
                                href="/"
                                className="flex gap-1 font-bold text-gray-700 items-center "
                                >
                                    <PaperAirplaneIcon className="h-6 w-6 text-primary" />
                                    <span>Hey, Zul!</span>
                                </a>
                            </div>
                            {/** Primary */}
                            {/* <div className="hidden lg:flex gap-8">
                                <a href="/" className="">Home</a>
                                <a href="/">Abooot</a>
                                <a href="/">Blog</a>
                            </div> */}
                            <NavList navMain="hidden lg:flex gap-8" />
                        </div>
                        <div className="flex gap-6 items-center ml-auto">
                            <div className="xs:flex items-center gap-10">
                                <div>
                                    <button className="rounded-full border-solid border-2 border-gray-300 py-2 px-4 hover:bg-gray-700 hover:text-gray-100">
                                        Hire me
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Mobile navigation toggle */}
                        <div className="flex gap-6">
                            <div className="lg:hidden flex items-center">
                                <button onClick={() => setToggleMenu(!toggleMenu)}>
                                    <Bars3Icon className="h-6" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* mobile navigation */}
                <div
                    className={`fixed z-40 w-full  bg-gray-100 overflow-hidden flex flex-col lg:hidden gap-12  origin-top duration-700 ${
                    !toggleMenu ? "h-0" : "h-full"
                  }`}>
                    <div className="px-8">
                        {/* <div className="flex flex-col gap-8 font-bold tracking-wider">
                            <a href="/" className="border-l-4 border-gray-600">
                                Home
                            </a>
                            <a href="/">Abooot</a>
                            <a href="/">Blog</a>
                        </div> */}
                        <NavList navMain="flex flex-col gap-8 font-bold tracking-wider" />
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavigationBar