import React from "react";

type Props = {
    textAbooot: string;
}

const AboootPage = ({ textAbooot }: Props) => (
    <h1>{textAbooot}</h1>
)

export default AboootPage;