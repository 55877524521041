import React from "react";

type Props = {
    textBlog: string
}

const BlogPage = ({ textBlog }: Props) => (
    <h1>{textBlog}</h1>
)

export default BlogPage;